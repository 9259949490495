<template>
    <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    id="svg8"
    version="1.1"
    viewBox="0 0 80.000001 42"
    height="42mm"
    width="80mm">
        <defs
            id="defs2" />
        <metadata
            id="metadata5">
            <rdf:RDF>
            <cc:Work
                rdf:about="">
                <dc:format>image/svg+xml</dc:format>
                <dc:type
                rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                <dc:title></dc:title>
            </cc:Work>
            </rdf:RDF>
        </metadata>
        <g
            transform="translate(0,-254.99998)"
            id="layer1">
            <path
            id="path4520"
            d="m 0.68925967,255.00058 c -0.6241669,0 -0.7609166,-0.0332 -0.6584417,0.44543 0.073313,0.42051 0.4272086,0.96471 0.6183121,1.17406 L 37.074226,295.99183 c 1.538299,1.38791 4.479243,1.29977 5.851542,0 l 36.425097,-39.37176 c 0.191104,-0.20935 0.545,-0.75355 0.618313,-1.17406 0.102475,-0.47868 -0.03423,-0.44543 -0.658439,-0.44543 h -5.052117 c -1.647558,-0.004 -1.856646,-0.0108 -2.75311,0.81793 L 39.999997,289.87232 8.4944885,255.81851 C 7.5980248,254.98914 7.3889276,254.9968 5.7413778,255.00058 Z"
            style="sfont-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:6.09319878;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate" />
        </g>
    </svg>
</template>
